import {useEffect, useMemo, useState} from 'react';
import {genericMessageHandler, useQueryParams} from 'shared/helpers';
import {loginBTD, createBenchtop, saveBenchtop} from 'service';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetConfigurationQuery} from 'store/customer/appSlice';
import BenchtopAdapter from 'components/customer/BenchtopDesigner/BenchtopAdapter';
import {
    invalidateJob,
    invalidateJobCost,
} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';

const benchtopAdapter = new BenchtopAdapter('btdFrame');

export const useBenchtop = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {userProfile} = useAppContext();
    const {jobId, roomId} = useParams();
    const {notify} = useNotificationContext();
    const {room} = useJobContext();
    const {benchType, benchId: queryBenchId} = useQueryParams();

    const {data: configuration, isLoading: isConfigurationLoading} =
        useGetConfigurationQuery();

    const [iframe, setIframe] = useState(false);
    const [loader, setLoader] = useState(true);
    const [{benchId, btdUsername}, setBtdLogin] = useState({
        benchId: null,
        btdUsername: null,
    });

    const benchtopSaved = useMemo(
        () => async (benchId) => {
            const realJobId = jobId - 10000;

            try {
                setIframe(false);
                await saveBenchtop(realJobId, roomId, benchId);

                if (userProfile.isBenchtopAvailable) {
                    navigate(`/v2/job/${jobId}/benchtop-module`);
                } else {
                    navigate(
                        `/v2/job/${jobId}/room/${roomId}/benchtop-designer`
                    );
                }

                setIframe(true);
                genericMessageHandler(
                    notify,
                    {
                        message:
                            'Benchtop successfully added to your selected room',
                    },
                    'success'
                );
                dispatch(invalidateJob());
                dispatch(invalidateJobCost());
            } catch (e) {
                genericMessageHandler(notify, e);
            }
        },
        [jobId, roomId, notify]
    );

    const addButtonToAdapter = (key, button) => {
        let butttonIndex = -1;
        benchtopAdapter.buttons.forEach((button, index) => {
            if (button.key == key) {
                butttonIndex = index;
            }
        });

        benchtopAdapter.addButton(key, button, butttonIndex);
    };

    useEffect(() => {
        if (queryBenchId && room.hasOwnProperty('jobBenchtops')) {
            const selectedBench = room.jobBenchtops.filter(
                (bench) => bench.externalBenchId == queryBenchId
            );

            if (selectedBench.length) {
                setBtdLogin({
                    benchId: queryBenchId,
                    btdUsername: selectedBench[0].externalUsername,
                });

                return;
            }
        }

        if (room.hasOwnProperty('id')) {
            const realJobId = jobId - 10000;

            createBenchtop(realJobId, room.id, benchType).then(
                ({data, username}) => {
                    setBtdLogin({
                        benchId: data && data.bench_id,
                        btdUsername: username,
                    });
                }
            );
        }
    }, [room, queryBenchId, benchType, jobId]);

    useEffect(() => {
        if (btdUsername && benchId) {
            const fetchData = async () => {
                try {
                    const additionalSafe = await loginBTD(btdUsername);

                    additionalSafe &&
                        benchtopAdapter.setCookie(
                            additionalSafe.__php_session_id,
                            additionalSafe.__php_session_name
                        );

                    benchtopAdapter.setupNewUI();

                    benchtopAdapter.loadBench(benchId);

                    setIframe(true);
                } catch (e) {
                    genericMessageHandler(notify, e);
                }

                setLoader(false);
            };

            fetchData();
        }
    }, [btdUsername, benchId]);

    useEffect(() => {
        if (isConfigurationLoading) {
            return;
        }

        setIframe(false);
        setLoader(true);

        benchtopAdapter.setOrigin(configuration.btdEmbedURL);
        benchtopAdapter.init(benchtopSaved, roomId);
    }, [isConfigurationLoading, configuration, benchtopSaved, roomId]);

    const buttonEvent = (eventType) => {
        benchtopAdapter.sendMessage({
            messageType: 'v2-ui-event',
            event: eventType,
        });
    };

    return {
        iframe,
        buttonEvent,
        addButtonToAdapter,
        loader,
    };
};
