import {useEffect, useState, useRef} from 'react';
import {Axios, getUserDetails, truncateindexedDBObjects} from 'service';
import {genericMessageHandler} from 'shared/helpers';
import {useNotificationContext} from 'contexts';

import type {Country} from 'shared/types';
import {AxiosError} from 'axios';
import {
    User,
    useGetCountriesQuery,
} from 'components/customer/Auth/store/userSlice';
import {useAppDispatch} from 'store/customer';
import {
    SESSION_CHANGE_MESSAGE,
    messageSet,
    redirectToSet,
} from 'components/customer/Auth/store/authSlice';

const getUserLevel = (level: number) => {
    if (level < 0) return 'USER_NONE';

    const levels = [
        'USER_ADMIN',
        'USER_MANUFACTURER',
        'USER_CUSTOMER',
        'USER_SUPPLIER',
    ];

    return levels[Number(level)];
};

export const useUserProfile = (userProfile: User): string => {
    const [userLevel, setUserLevel] = useState<string>('');
    const dispatch = useAppDispatch();

    // Checking for referer only when UI is switched or user first logged in.
    const hasAppReferrer = useRef<boolean>(true);
    const checkingSessionTimeout = useRef<boolean>(false);

    useEffect(() => {
        // NOTE: This is just a temporary fix for session timeout issue
        Axios.onSuspendedUser = () => {
            // setUserProfile({...userProfile, ...{isSuspended: true}});
        };

        Axios.onSessionChangedUser = (response) => {
            setUserLevel(getUserLevel(-1));
            dispatch(messageSet(SESSION_CHANGE_MESSAGE));
            dispatch(redirectToSet(response.redirectTo));
        };

        Axios.onLoggedOut = async () => {
            if (!checkingSessionTimeout.current) {
                checkingSessionTimeout.current = true;
                try {
                    await getUserDetails();
                } catch (e) {
                    const error = e as AxiosError;
                    if (
                        error.response &&
                        (error.response.status === 400 ||
                            error.response.status === 401 ||
                            error.response.status === 403)
                    ) {
                        checkingSessionTimeout.current = false;
                        setUserLevel(getUserLevel(-1));
                    }
                }
            }
        };

        // NOTE: remove this when these data are fetched using
        // rtk query
        truncateindexedDBObjects(['product_styles', 'products']).catch(() => {
            // console.log(e)
        });
        // NOTE: remove this when these data are fetched using
        // rtk query

        if (document.referrer.indexOf('v2') < 0 && hasAppReferrer.current) {
            hasAppReferrer.current = false;
        }
    }, []);

    useEffect(() => {
        if (userProfile) {
            setUserLevel(getUserLevel(userProfile.level));
        }
    }, [userProfile]);

    return userLevel;
};

interface Countries {
    Countries?: {
        [key: string]: {
            id: number;
            name: string;
        }[];
    };
}

export const processCountriesStates = (countries: Country[]): Countries => {
    const data: Countries = {};

    countries.forEach((country) => {
        if (data.Countries) {
            data.Countries[country.name] = country.states;
        } else {
            data.Countries = {
                [country.name]: country.states,
            };
        }
    });

    return data;
};

export const useCountries = (
    userProfile: User
): {places: object; stateLabel: string} => {
    const [places, setPlaces] = useState({});
    const [stateLabel, setStateLabel] = useState('');
    const {data: countries, isError, error} = useGetCountriesQuery();
    const {notify} = useNotificationContext();

    useEffect(() => {
        if (userProfile && countries) {
            if (!userProfile.countryName) {
                return;
            }

            if (userProfile.isSuspended == 1) {
                return;
            }

            const country: Country = countries.find(
                (country) => country.name == userProfile.countryName
            );
            setStateLabel(country.stateLabel ? country.stateLabel : '');

            const statesInFormat = processCountriesStates(countries);
            setPlaces(statesInFormat);
        }
    }, [userProfile, countries]);

    useEffect(() => {
        if (isError) {
            let message = 'Failed to fetch countries';
            if (error && 'message' in error) {
                message = error.message;
            }

            genericMessageHandler(notify, {message});
        }
    }, [isError]);

    return {
        places,
        stateLabel,
    };
};
