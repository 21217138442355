import {useEffect, useState} from 'react';
import {useNotificationContext} from 'contexts';
import {genericMessageHandler, usePromise} from 'shared/helpers';
import {getProductSizeDefaults} from 'service';

// NOTE: Setting page is using this hook.
// Update that to use rtkquery and remove this hook.
export const useCabinetSizeDefaultsData = () => {
    const [loader, setLoader] = useState(false);
    const {notify} = useNotificationContext();
    const [productSizes, setProductSizes] = useState([]);
    const [refreshHack, setRefreshHack] = useState({});

    useEffect(() => {
        setLoader(true);
        const resetCache = refreshHack.hasOwnProperty('noCache')
            ? refreshHack.noCache
            : false;
        const productSizePromise = getProductSizeDefaults(resetCache);

        return usePromise(
            ([productSizes]) => {
                setProductSizes(
                    productSizes.map((productSize) => ({
                        baseHeight: productSizes.baseHeight,
                        baseDepth: productSizes.baseDepth,
                        ...productSize,
                    }))
                );
                setLoader(false);
            },
            [productSizePromise],
            (error) => {
                genericMessageHandler(notify, error);
                setLoader(false);
            }
        );
    }, [refreshHack]);

    return {
        loader,
        setLoader,
        productSizes,
        refresh: setRefreshHack,
    };
};
