import React, {useEffect, useState, useMemo} from 'react';
import {object, string, number} from 'yup';
import {useAppContext, useNotificationContext} from 'contexts';
import {
    genericMessageHandler,
    localeDateString,
    randomString,
    usePromise,
} from 'shared/helpers';
import {
    getSubscriptionList,
    updateDeliveryAddress,
    updateDetails,
    deleteCabinetSizes,
    saveCabinetSizes,
    saveGapSizes,
    deleteGapSizes,
} from 'service';
import {FormControl} from 'shared';
import {useCabinetSizeDefaultsData} from 'hooks/Settings/useCabinetSizeDefaultsData';
import {useMarginDefaultsData} from 'hooks/Settings/useMarginDefaultsData';
import {
    saveMaterialDefaults,
    setProductSizeDefault,
    setGapSizeDefault,
} from 'service/SettingsService';
import {getPhoneRegex} from 'shared/validation/phoneNumber';
import {webUrl} from 'shared/validation/validationRegex';

const customerDetailsSchema = (userProfile, places) => {
    let countryName = null;
    let states = [];

    if (userProfile.hasOwnProperty('countryName')) {
        countryName = userProfile.countryName;

        const allStates =
            places.hasOwnProperty('Countries') &&
            places.Countries.hasOwnProperty(userProfile.countryName)
                ? places.Countries[userProfile.countryName]
                : [];

        states = allStates.map((state) => state.id);
    }

    const phoneMatch = getPhoneRegex(countryName);

    return object().shape({
        name: string().required('Please enter your name'),
        phNumber: string()
            .required('Please enter your phone number')
            .matches(phoneMatch, 'Please enter a valid phone number'),
        email: string().required('Please enter your email'),
        address: string().required('Please enter your street address'),
        suburb: string().required('Please enter your suburb'),
        postcode: number()
            .positive('Please enter a valid Postcode')
            .integer('Please enter a valid Postcode')
            .required('Please enter your Postcode'),
        state: number()
            .required('Please enter your state')
            .oneOf(states, 'Please enter a valid State'),
        websiteUrl: string()
            .matches(webUrl, 'Please enter a valid website address')
            .nullable()
            .notRequired(),
        abn: number()
            .typeError('Please enter a valid ABN number')
            .nullable()
            .notRequired(),
        acn: number()
            .typeError('Please enter a valid ACN number')
            .nullable()
            .notRequired(),
        mobile: number()
            .typeError('Please enter a valid mobile')
            .nullable()
            .notRequired(),
    });
};

const deliveryDetailsSchema = (userProfile) => {
    return object().shape({
        address: string().required('Please enter your street address'),
        suburb: string().required('Please enter your suburb'),
        postcode: number()
            .positive('Please enter a valid Postcode')
            .integer('Please enter a valid Postcode')
            .required('Please enter your Postcode'),
    });
};

export const useCabinetSizesTableConfig = (showDialog) => {
    const {notify} = useNotificationContext();
    const {loader, setLoader, productSizes, refresh} =
        useCabinetSizeDefaultsData();
    const [newCabinetSizes, setNewCabinetSizes] = useState([]);
    const {userProfile, refreshUserData} = useAppContext();
    const [defaultSize, setDefaultSize] = useState();

    useEffect(() => {
        setDefaultSize(userProfile.defaultSize);
    }, [userProfile]);

    const [editCabinetSizes, setEditCabinetSizes] = useState({});

    const formatEdit = (value, row, fieldName) => {
        if (Object.keys(editCabinetSizes).includes(row.id + '')) {
            return (
                <div>
                    <FormControl
                        type="number"
                        name={fieldName}
                        hasFormik={false}
                        value={editCabinetSizes[row.id][fieldName]}
                        selectHandler={(name, value) => {
                            editCabinetSizes[row.id][name] = value;
                            setEditCabinetSizes(editCabinetSizes);
                        }}
                    />
                </div>
            );
        }

        return value;
    };

    const formatEditName = (value, row, fieldName) => {
        if (Object.keys(editCabinetSizes).includes(row.id + '')) {
            return (
                <div>
                    <FormControl
                        type="text"
                        name={fieldName}
                        hasFormik={false}
                        value={editCabinetSizes[row.id][fieldName]}
                        selectHandler={(name, value) => {
                            editCabinetSizes[row.id][name] = value;
                            setEditCabinetSizes(editCabinetSizes);
                        }}
                    />
                </div>
            );
        }

        return value;
    };

    const addCabinetSize = () => {
        const productSizesTemp = [...newCabinetSizes];

        const guid = randomString(7);
        const cabinetSize = {
            id: guid,
            name: '',
            baseHeight: 0,
            baseDepth: 0,
            upperHeight: 0,
            upperDepth: 0,
            tallHeight: 0,
            tallDepth: 0,
        };

        productSizesTemp.push(cabinetSize);

        const editCabinetSizesTemp = {...editCabinetSizes};
        editCabinetSizesTemp[guid] = cabinetSize;

        setEditCabinetSizes(editCabinetSizesTemp);
        setNewCabinetSizes(productSizesTemp);
    };

    const editCabinetTop = (row) => {
        const ids = {...editCabinetSizes};
        ids[row.id] = {...row};
        setEditCabinetSizes(ids);
    };

    const saveAction = async (row, event) => {
        const cabinetSizes = {...editCabinetSizes};
        await saveCabinetSizes(cabinetSizes[row.id]);

        delete cabinetSizes[row.id];

        setEditCabinetSizes(cabinetSizes);
        setNewCabinetSizes([]);
        refresh({noCache: true});
    };

    const cancelAction = (row) => {
        const cabinetSizes = {...editCabinetSizes};
        delete cabinetSizes[row.id];
        // remove only current row
        setNewCabinetSizes(
            newCabinetSizes.filter((item) => item.id !== row.id)
        );
        setEditCabinetSizes(cabinetSizes);
    };

    const deleteAction = async (row) => {
        const button = event.target.parentElement;
        showDialog({
            title: 'Delete Product Size',
            message: 'Are you sure you want to delete this Product size?',
            yes: async () => {
                button.disabled = true;
                try {
                    const response = await deleteCabinetSizes(row.id);

                    if (response)
                        genericMessageHandler(
                            notify,
                            {message: 'Product Size deleted'},
                            'success'
                        );
                    else
                        genericMessageHandler(notify, {
                            message:
                                'Could not delete the product size right now, Please try again later.',
                        });
                    refresh({noCache: true});
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
        });
    };

    const productSizeTableConfig = {
        fields: [
            {
                fieldName: 'id',
                width: 22,
                checked: defaultSize,
                type: 'radio',
                title: '',
                action: async (row) => {
                    setLoader(true);
                    await setProductSizeDefault(row.id);
                    refreshUserData();
                    setLoader(false);
                },
            },
            {
                fieldName: 'name',
                title: 'Name',
                format: formatEditName,
                alignLeft: true,
            },
            {fieldName: 'baseHeight', title: 'Base Height', format: formatEdit},
            {fieldName: 'baseDepth', title: 'Base Depth', format: formatEdit},
            {
                fieldName: 'upperHeight',
                title: 'Upper Height',
                format: formatEdit,
            },
            {fieldName: 'upperDepth', title: 'Upper Depth', format: formatEdit},
            {fieldName: 'tallHeight', title: 'Tall Height', format: formatEdit},
            {fieldName: 'tallDepth', title: 'Tall Depth', format: formatEdit},
        ],
        data: [...productSizes, ...newCabinetSizes],
        options: {
            title: '',
            width: 55,
            buttons: [
                {
                    iconName: 'Options-Edit.svg',
                    title: 'Edit Sizes',
                    action: editCabinetTop,
                    isHidden: (row) =>
                        Object.keys(editCabinetSizes).includes(row.id + ''),
                },
                {
                    iconName: 'Button-Tick-Secondary.svg',
                    title: 'Save Sizes',
                    action: saveAction,
                    isHidden: (row) =>
                        !Object.keys(editCabinetSizes).includes(row.id + ''),
                },
                {
                    iconName: 'Options-Delete.svg',
                    title: 'Delete Sizes',
                    action: deleteAction,
                    isHidden: (row) => {
                        if (userProfile && userProfile.defaultSize == row.id) {
                            return true;
                        }
                        return Object.keys(editCabinetSizes).includes(
                            row.id + ''
                        );
                    },
                },
                {
                    iconName: 'Button-Error-Gray.svg',
                    title: 'Cancel',
                    action: cancelAction,
                    isHidden: (row) =>
                        !Object.keys(editCabinetSizes).includes(row.id + ''),
                },
            ],
        },
        noRecordsText: `No Cabinet Sizes Available.`,
    };

    return {
        addCabinetSize,
        productSizeTableConfig,
        loader,
    };
};

export const useMarginTableConfig = (showDialog) => {
    const {notify} = useNotificationContext();
    const {loader, gapSizes, refreshGap, setLoader} = useMarginDefaultsData();
    const {userProfile, refreshUserData} = useAppContext();

    const [newGapSizes, setNewGapSizes] = useState([]);
    const [editGapSizes, setEditGapSizes] = useState({});
    const [defaultGap, setDefaultGap] = useState();

    useEffect(() => {
        setDefaultGap(userProfile.defaultGap);
    }, [userProfile]);

    const formatEdit = (value, row, fieldName) => {
        if (Object.keys(editGapSizes).includes(row.id + '')) {
            return (
                <div>
                    <FormControl
                        type="number"
                        name={fieldName}
                        hasFormik={false}
                        value={editGapSizes[row.id][fieldName]}
                        selectHandler={(name, value) => {
                            editGapSizes[row.id][name] = value;
                            setEditGapSizes(editGapSizes);
                        }}
                    />
                </div>
            );
        }

        return value;
    };

    const formatEditName = (value, row, fieldName) => {
        if (Object.keys(editGapSizes).includes(row.id + '')) {
            return (
                <div>
                    <FormControl
                        type="text"
                        name={fieldName}
                        hasFormik={false}
                        value={editGapSizes[row.id][fieldName]}
                        selectHandler={(name, value) => {
                            editGapSizes[row.id][name] = value;
                            setEditGapSizes(editGapSizes);
                        }}
                    />
                </div>
            );
        }

        return value;
    };

    const editGap = (row) => {
        const ids = {...editGapSizes};
        ids[row.id] = {...row};
        setEditGapSizes(ids);
    };

    const saveAction = async (row, event) => {
        const gapSizes = {...editGapSizes};
        await saveGapSizes(gapSizes[row.id]);

        delete gapSizes[row.id];

        setEditGapSizes(gapSizes);
        setNewGapSizes([]);
        refreshGap({noCache: true});
    };

    const cancelAction = (row) => {
        const gapSizes = {...editGapSizes};
        delete gapSizes[row.id];
        // remove only current row
        setNewGapSizes(newGapSizes.filter((item) => item.id !== row.id));
        setEditGapSizes(gapSizes);
    };

    const deleteAction = async (row) => {
        const button = event.target.parentElement;
        showDialog({
            title: 'Delete Gap Size',
            message: 'Are you sure you want to delete this Gap size?',
            yes: async () => {
                button.disabled = true;
                try {
                    const response = await deleteGapSizes(row.id);

                    if (response)
                        genericMessageHandler(
                            notify,
                            {message: 'Gap Size deleted'},
                            'success'
                        );
                    else
                        genericMessageHandler(notify, {
                            message:
                                'Could not delete the gap size right now, Please try again later.',
                        });
                    refreshGap({noCache: true});
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
        });
    };

    const addGapSize = () => {
        const gapSizesTemp = [...newGapSizes];

        const guid = randomString(7);
        const gapSize = {
            id: guid,
            name: '',
            doorGap: 0,
            drawerGap: 0,
            upperTopGap: 0,
            upperBottomGap: 0,
            baseTopGap: 0,
            baseBottomGap: 0,
            left: 0,
            right: 0,
        };

        gapSizesTemp.push(gapSize);

        const editGapSizesTemp = {...editGapSizes};
        editGapSizesTemp[guid] = gapSize;

        setEditGapSizes(editGapSizesTemp);
        setNewGapSizes(gapSizesTemp);
    };

    const marginTableConfig = {
        fields: [
            {
                fieldName: 'id',
                width: 22,
                checked: defaultGap,
                type: 'radio',
                title: '',
                action: async (row) => {
                    setLoader(true);
                    await setGapSizeDefault(row.id);
                    refreshUserData();
                    setLoader(false);
                },
            },
            {
                fieldName: 'name',
                title: 'Name',
                format: formatEditName,
                alignLeft: true,
            },
            {
                fieldName: 'doorGap',
                title: 'Door Gap Margin',
                format: formatEdit,
            },
            {
                fieldName: 'drawerGap',
                title: 'Drawer Gap Margin',
                format: formatEdit,
            },
            {
                fieldName: 'upperTopGap',
                title: 'Upper Top Margin',
                format: formatEdit,
            },
            {
                fieldName: 'upperBottomGap',
                title: 'Upper Bottom Margin',
                format: formatEdit,
            },
            {
                fieldName: 'baseTopGap',
                title: 'Base Top Margin',
                format: formatEdit,
            },
            {
                fieldName: 'baseBottomGap',
                title: 'Base Bottom Margin',
                format: formatEdit,
            },
            {fieldName: 'left', title: 'Left Margin', format: formatEdit},
            {fieldName: 'right', title: 'Right Margin', format: formatEdit},
        ],
        data: [...gapSizes, ...newGapSizes],
        options: {
            title: '',
            width: 55,
            buttons: [
                {
                    iconName: 'Options-Edit.svg',
                    title: 'Edit Sizes',
                    action: editGap,
                    isHidden: (row) =>
                        Object.keys(editGapSizes).includes(row.id + ''),
                },
                {
                    iconName: 'Button-Tick-Secondary.svg',
                    title: 'Save Sizes',
                    action: saveAction,
                    isHidden: (row) =>
                        !Object.keys(editGapSizes).includes(row.id + ''),
                },
                {
                    iconName: 'Options-Delete.svg',
                    title: 'Delete Sizes',
                    action: deleteAction,
                    isHidden: (row) => {
                        if (userProfile && userProfile.defaultGap == row.id) {
                            return true;
                        }
                        return Object.keys(editGapSizes).includes(row.id + '');
                    },
                },
                {
                    iconName: 'Button-Error-Gray.svg',
                    title: 'Cancel',
                    action: cancelAction,
                    isHidden: (row) =>
                        !Object.keys(editGapSizes).includes(row.id + ''),
                },
            ],
        },
        noRecordsText: `No Margin Sizes Available.`,
    };

    return {
        addGapSize,
        marginTableConfig,
        loader,
    };
};

const useSubscriptionsData = () => {
    const [loading, setLoading] = useState(false);
    const {notify} = useNotificationContext();
    const [subscriptions, setSubscriptions] = useState([]);
    useEffect(() => {
        let cleanUp = () => {};
        setLoading(true);

        const subscriptionPromise = getSubscriptionList();

        cleanUp = usePromise(
            ([subscriptions]) => {
                setSubscriptions(
                    subscriptions.map((subscription) => ({
                        id: subscription.id,
                        startDate: subscription.startDate,
                        ...subscription,
                    }))
                );
                setLoading(false);
            },
            [subscriptionPromise],
            (error) => {
                genericMessageHandler(notify, error);
            }
        );

        return cleanUp;
    }, []);

    return {
        loading,
        subscriptions,
    };
};

export const useSubscriptionTableConfig = () => {
    const {subscriptions} = useSubscriptionsData();
    const subscriptionTableConfig = {
        fields: [
            {fieldName: 'displayId', title: '#'},
            {fieldName: 'typeName', title: 'Package'},
            {fieldName: 'startDate', title: 'From', format: localeDateString},
            {fieldName: 'endDate', title: 'To', format: localeDateString},
            {fieldName: 'cost', title: 'Cost inc. Tax'},
            {fieldName: 'paymentMethodName', title: 'Method'},
            {fieldName: 'paidName', title: 'Paid'},
            {fieldName: 'invoiceSentDate', title: 'Invoice Received'},
        ],
        data: subscriptions,
        options: {
            title: '',
            width: 55,
            buttons: [
                {
                    iconName: 'Options-PDF.svg',
                    title: 'Get PDF',
                    action: (row) =>
                        window.open(
                            `/customerSubscriptionPDF.php?subscriptionId=${row.id}`
                        ),
                },
            ],
        },
        noRecordsText: `No Subscritions Available.`,
    };

    return {
        subscriptionTableConfig,
        dialog,
    };
};

export const useDeliveryAddressSubmitHandler = () => {
    const {notify} = useNotificationContext();
    const {userProfile, refreshUserData} = useAppContext();
    const [loadingSave, setLoadingSave] = useState(false);
    const DeliveryDetailsSchema = deliveryDetailsSchema(userProfile);

    let fieldMap = {
        address: 'address',
        suburb: 'suburb',
        postcode: 'postcode',
        state: 'state',
    };

    let formData = new FormData();

    let deliveryAddressSubmitHandler = async (values) => {
        setLoadingSave(true);
        Object.keys(fieldMap).forEach((key) => {
            formData.append(fieldMap[key], values[key]);
        });
        let goTo = `/v2/account-settings`;

        if (!userProfile.manufacturerAllowCustomerEdit) {
            genericMessageHandler(notify, {
                message: 'Cannot update details',
            });
            setLoadingSave(false);
        } else {
            try {
                let response = await updateDeliveryAddress(formData);

                refreshUserData();

                genericMessageHandler(notify, {message: response}, 'success');

                setLoadingSave(false);
            } catch (error) {
                genericMessageHandler(notify, error);
            }
        }
    };

    return {
        deliveryAddressSubmitHandler,
        DeliveryDetailsSchema,
        loadingSave,
    };
};

export const useDetailsSubmitHandler = () => {
    const {notify} = useNotificationContext();
    const {userProfile, refreshUserData, places} = useAppContext();
    const [loadingSave, setLoadingSave] = useState(false);
    const CustomerDetailsSchema = useMemo(
        () => customerDetailsSchema(userProfile, places),
        [userProfile, places]
    );

    const fieldMap = {
        name: 'name',
        email: 'email',
        phNumber: 'phNumber',
        mobile: 'mobile',
        address2: 'address2',
        websiteUrl: 'websiteUrl',
        abn: 'abn',
        acn: 'acn',
        businessName: 'businessName',
        cc: 'cc',
        address: 'address',
        suburb: 'suburb',
        postcode: 'postcode',
        state: 'state',
        manuPermission: 'manuPermission',
    };

    const formData = new FormData();

    const detailsSubmitHandler = async (values) => {
        setLoadingSave(true);
        Object.keys(fieldMap).forEach((key) => {
            formData.append(fieldMap[key], values[key] ? values[key] : '');
        });

        if (!userProfile.manufacturerAllowCustomerEdit) {
            genericMessageHandler(notify, {
                message: 'Cannot update details',
            });
            setLoadingSave(false);
        } else {
            try {
                const response = await updateDetails(formData, values);
                refreshUserData();

                genericMessageHandler(notify, {message: response}, 'success');

                setLoadingSave(false);
            } catch (error) {
                genericMessageHandler(notify, error);
                setLoadingSave(false);
            }
        }
    };

    return {
        detailsSubmitHandler,
        CustomerDetailsSchema,
        loadingSave,
    };
};

// NOTE: This hooks looks like a misnomer
// update this when someone gets a chance.
// Also everything inside can be memoized using useMemo and useCallback :)
export const useSaveMaterialDefaults = () => {
    const {userProfile, refreshUserData} = useAppContext();
    const {notify} = useNotificationContext();
    const [loadingSave, setLoadingSave] = useState(false);
    const fieldMap = {
        // Hardware settings fields
        hingeStyle: 'user_hinge_style',
        drawerSystem: 'user_drawer_style',
        cabinetTop: 'roomTopDefault',
        supplyMethod: 'user_include_assembly',
        hardwareInclusions: 'user_exclude_hardware',
        adjustableLegs: 'user_adjustable_legs',
        // Materials fields
        exteriorType: 'user_ext_material_type',
        exteriorBrandMaterial: 'user_ext_door_brand',
        exteriorCategory: 'user_ext_door_category',
        exteriorStyle: 'user_ext_door_style',
        exteriorBrand: 'user_ext_material_brand',
        exteriorFinish: 'user_ext_material_finish',
        exteriorSubstrate: 'user_ext_material_substrate',
        exteriorColor: 'user_ext_material_colour',
        exteriorEdgeBrand: 'user_ext_edge_brand',
        exteriorEdgeFinish: 'user_ext_edge_finish',
        exteriorEdgeColor: 'user_ext_edge_colour',
        exteriorColorHorizontal: 'user_ext_material_horGrain',
        exteriorColorDoubleSided: 'user_ext_material_doubleSided',
        exteriorColorCustomName: 'user_ext_material_custom_color',
        carcaseType: 'user_carc_material_type',
        carcaseBrand: 'user_carc_material_brand',
        carcaseFinish: 'user_carc_material_finish',
        carcaseSubstrate: 'user_carc_material_substrate',
        carcaseColor: 'user_carc_material_colour',
        carcaseEdgeBrand: 'user_carc_edge_brand',
        carcaseEdgeFinish: 'user_carc_edge_finish',
        carcaseEdgeColor: 'user_carc_edge_colour',
        carcaseColorHorizontal: 'user_carc_material_horGrain',
        carcaseColorDoubleSided: 'user_carc_material_doubleSided',
        carcaseColorCustomName: 'user_carc_material_custom_color',
        // settings fields
        default_room: 'add_room_on_new_job',
        room_assistant: 'room_assistant',
        freight: 'default_freight_option',
        edgeTop: 'advanced_edge_top',
        edgeBottom: 'advanced_edge_bottom',
        edgeLeft: 'advanced_edge_left',
        edgeRight: 'advanced_edge_right',
        borderWidthTop: 'user_border_width_top',
        borderWidthBottom: 'user_border_width_bottom',
        borderWidthLeft: 'user_border_width_left',
        borderWidthRight: 'user_border_width_right',
        railHeight: 'advanced_rail_height',
        railWidth: 'advanced_rail_width',
        barWidth: 'advanced_bar_width',
        shelfSetBack: 'user_shelf_set_back',
        toeKickHeight: 'user_toe_kick_height',
        measurementUnit: 'default_measurement_unit_option',
        depotId: 'depot_id',
    };

    const initialValues = {
        // Hardware settings fields
        hingeStyle: userProfile.defaultHinge,
        drawerSystem: userProfile.defaultDrawer,
        cabinetTop: userProfile.defaultRoomTop,
        supplyMethod: userProfile.defaultIncludeAssembly,
        hardwareInclusions: userProfile.defaultIncludeHardware,
        // Materials fields
        exteriorType: parseInt(userProfile.defaultExtMaterialType),
        exteriorCategory: userProfile.defaultDoorCategory,
        exteriorStyle: userProfile.defaultDoor,
        exteriorBrand: userProfile.defaultExtBrand,
        exteriorFinish: userProfile.defaultExtFinish,
        exteriorSubstrate: userProfile.defaultExtSubstrate,
        exteriorColor: userProfile.defaultExtMaterial,
        exteriorEdgeBrand: userProfile.defaultExtEdgeBrand,
        exteriorEdgeFinish: userProfile.defaultExtEdgeFinish,
        exteriorEdgeColor: userProfile.defaultExtEdge,
        exteriorColorHorizontal: userProfile.defaultExtHorGrain,
        exteriorColorDoubleSided: userProfile.defaultExtDoubleSided,
        exteriorColorCustomName: userProfile.defaultExtCustomColor,
        carcaseType: userProfile.defaultCarcMaterialType,
        carcaseBrand: userProfile.defaultCarcBrand,
        carcaseFinish: userProfile.defaultCarcFinish,
        carcaseSubstrate: userProfile.defaultCarcSubstrate,
        carcaseColor: userProfile.defaultCarcMaterial,
        carcaseEdgeBrand: userProfile.defaultCarcEdgeBrand,
        carcaseEdgeFinish: userProfile.defaultCarcEdgeFinish,
        carcaseEdgeColor: userProfile.defaultCarcEdge,
        carcaseColorHorizontal: userProfile.defaultCarcHorGrain,
        carcaseColorDoubleSided: userProfile.defaultCarcDoubleSided,
        carcaseColorCustomName: userProfile.defaultCarcCustomColor,
        // settings fields
        default_room: userProfile.addDefaultRoomOnNewJobCreate ? 1 : 0,
        room_assistant: userProfile.room_assistant,
        adjustableLegs: userProfile.defaultAdjustableLegs ? 1 : 0,
        freight: userProfile.defaultFreightOption,
        shelfSetBack: userProfile.defaultShelfSetBack,
        toeKickHeight: userProfile.defaultToeKickHeight,
        edgeTop: userProfile.defaultAdvancedEdgeTop,
        edgeBottom: userProfile.defaultAdvancedEdgeBottom,
        edgeLeft: userProfile.defaultAdvancedEdgeLeft,
        edgeRight: userProfile.defaultAdvancedEdgeRight,
        borderWidthTop: userProfile.defaultBorderWidthTop,
        borderWidthBottom: userProfile.defaultBorderWidthBottom,
        borderWidthLeft: userProfile.defaultBorderWidthLeft,
        borderWidthRight: userProfile.defaultBorderWidthRight,
        railHeight: userProfile.defaultAdvancedRailHeight,
        railWidth: userProfile.defaultAdvancedRailWidth,
        barWidth: userProfile.defaultAdvancedBarWidth,
        measurementUnit: userProfile.defaultMeasurementUnit,
        depotId: userProfile.defaultDepotId,
    };

    const materialDefaultSubmitHandler = async (values) => {
        const formData = new FormData();
        const data = {...initialValues, ...values};
        setLoadingSave(true);
        Object.keys(fieldMap).forEach((key) => {
            // convert boolean/string boolean to numeric 1/0
            if (typeof data[key] == 'boolean') {
                data[key] = data[key] ? 1 : 0;
            }

            if (
                typeof data[key] == 'string' &&
                (data[key].toLowerCase() === 'false' ||
                    data[key].toLowerCase() === 'true')
            ) {
                data[key] = data[key].toLowerCase() === 'true' ? 1 : 0;
            }
            formData.append(fieldMap[key], data[key]);
        });

        formData.append('isDefaultDoorAdvanced', values.isAdvancedDoor);
        try {
            const response = await saveMaterialDefaults(formData);
            refreshUserData();
            genericMessageHandler(notify, {message: response}, 'success');

            setLoadingSave(false);
        } catch (error) {
            genericMessageHandler(notify, error);
        }
    };

    return {
        materialDefaultSubmitHandler,
        loadingSave,
        userProfile,
    };
};
