import React, {RefObject} from 'react';
import {ShapeData, TDLD} from '@cabinetsbycomputer/top-down-layout-designer';
import {Col, Row} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {
    useConfirmationDialog,
    ConfirmationOption,
} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {StatusMessage} from 'components/customer/LayoutDesigner/LayoutDesigner';

const ShapeSelection = ({
    shapes,
    onSelection,
    hideDialog,
}: {
    shapes: ShapeData[];
    onSelection: (selection: ShapeData) => void;
    hideDialog: () => void;
}) => {
    const onSelectShape = (shape: ShapeData) => () => {
        onSelection(shape);
        hideDialog && hideDialog();
    };

    return (
        <Row>
            {shapes.map((shape) => {
                let icon = '';
                switch (shape.code) {
                    case 'rect':
                        icon = 'RectangularRoom.svg';
                        break;
                    case 'lshape':
                        icon = 'LShapedRoom.svg';
                        break;
                    case 'custom':
                        icon = 'CustomRoom.svg';
                        break;
                }

                return (
                    <Col
                        key={shape.code}
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={onSelectShape(shape)}>
                        <Icon
                            iconName={icon}
                            style={{
                                width: '75%',
                                display: 'block',
                                margin: '0 auto 15px',
                            }}
                        />
                        <strong>{shape.name}</strong>
                    </Col>
                );
            })}
        </Row>
    );
};

type showDialogType = ReturnType<typeof useConfirmationDialog>['showDialog'];

export const roomShapeSelection = ({
    showDialog,
    hideDialog,
    roomId,
    tdld,
    data,
}: {
    showDialog: showDialogType;
    hideDialog: () => void;
    roomId: number;
    tdld: TDLD;
    data: ShapeData[];
}) => {
    showDialog({
        title: `Room #${roomId} - Select Room Layout Shape`,
        message: (
            <ShapeSelection
                hideDialog={hideDialog}
                shapes={data}
                // eslint-disable-next-line @typescript-eslint/unbound-method -- No choice but to do it this way
                onSelection={tdld.onShapeSelected}
            />
        ),
        hideNoButton: true,
        hideYesButton: true,
        hideFooter: true,
        container: '.mainContent',
    });
};

export const userPrompt = ({
    showDialog,
    title,
    message,
    buttonNames,
    callback,
}: {
    showDialog: showDialogType;
    title: string;
    message: string;
    buttonNames?:
        | string
        | {
              btnYesLabel: string;
              btnNoLabel: string;
          };
    callback: (success: boolean) => void;
}) => {
    let buttons: ConfirmationOption['buttons'] = [];
    let hideConfirmationButtons = false;

    if (typeof buttonNames === 'string') {
        hideConfirmationButtons = true;

        buttons = [
            {
                name: buttonNames,
                show: true,
            },
        ];
    } else if (buttonNames != null && typeof buttonNames === 'object') {
        hideConfirmationButtons = true;

        buttons = [
            {
                name: buttonNames.btnYesLabel,
                action: () => callback(true),
                show: true,
            },
            {
                name: buttonNames.btnNoLabel,
                show: true,
            },
        ];
    }

    showDialog({
        title: title,
        message: message,
        hideYesButton: hideConfirmationButtons,
        hideNoButton: hideConfirmationButtons,
        buttons,
        yes: () => callback(true),
    });
};

export const showStatus = ({
    statusText,
    fadeOut,
    setStatusMessage,
    isMounted,
}: {
    statusText: string;
    fadeOut: boolean;
    setStatusMessage: (message?: StatusMessage) => void;
    isMounted: RefObject<boolean>;
}) => {
    if (statusText) {
        const statusMessage: StatusMessage = {message: statusText};

        if (fadeOut) {
            statusMessage.variant = 'success';
            statusMessage.iconName = 'Button-Tick.svg';
        } else {
            if (
                statusText.indexOf('Saving') > -1 ||
                statusText.indexOf('Retrying') > -1
            ) {
                statusMessage.variant = 'info';
                statusMessage.iconName = 'TailSpinGray.svg';
            } else {
                statusMessage.variant = 'danger';
                statusMessage.iconName = 'Button-Exclamation.svg';
            }
        }

        isMounted.current && setStatusMessage(statusMessage);

        if (fadeOut) {
            window.setTimeout(() => {
                isMounted.current && setStatusMessage();
            }, 3000);
        }
    }
};
