// @flow
import {
    useEffect,
    useRef,
    useState,
    useLayoutEffect,
    useMemo,
    useCallback,
} from 'react';
import {useFormikContext} from 'formik';
import {COMPONENT_TYPES, ComponentPainter} from 'shared';
import {useProductContext, useAppContext} from 'contexts';
import {
    previewSubpanels,
    validSubpanels,
    getEdgeVisibility,
    calculateTotalDoors,
} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {HingeBlack, HingeWhite} from 'assets';
import {cloneDeep, debounce} from 'lodash';
import {POSITION_TYPES, getShelvesOptions} from 'hooks';
import excel from 'shared/Excel';

import {Fieldset} from 'components/customer/Product/entity/Fieldset';
import type {StickyPosition} from 'shared/types';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {useAppDispatch} from 'store/customer';
import {
    activeHorizontalMidRailSet,
    activeVerticalMidRailSet,
} from 'components/customer/QFPRedux/store/qfpSlice';

export const useStickyCanvas = (): StickyPosition => {
    const isSmallDevice = isDeviceSmall();
    const [position, setPosition] = useState<StickyPosition>({});

    const StickyCanvas = (event: Event) => {
        let target: Element;
        if (event.target instanceof Element) {
            target = event.target;
            if (
                target.classList.contains('component-section') &&
                !isSmallDevice
            ) {
                let topPosition = 90;
                const clientRect: ?Element =
                    document.querySelector('#canvas-container');
                const canvasWrapper: ?Element =
                    document.querySelector('#canvas-wrapper');
                const topMessage: ?Element =
                    document.querySelector('.cbc-top-message');

                const topMessageRectBounds =
                    topMessage && topMessage.getBoundingClientRect();

                if (topMessageRectBounds) {
                    topPosition += topMessageRectBounds.height + 10;
                }

                if (
                    clientRect &&
                    canvasWrapper &&
                    canvasWrapper.getBoundingClientRect().top <= topPosition
                ) {
                    const clientRectBounds = clientRect.getBoundingClientRect();

                    const document_: ?Element = document.body;

                    if (document_) {
                        setPosition({
                            position: 'fixed',
                            top: `${topPosition}px`,
                            right: `${
                                document_.getBoundingClientRect().width -
                                clientRectBounds.right
                            }px`,
                            width: `${clientRectBounds.width}px`,
                        });
                    }
                } else {
                    setPosition({
                        position: 'initial',
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (!isSmallDevice) {
            document.removeEventListener('scroll', StickyCanvas, true);
            document.addEventListener('scroll', StickyCanvas, true);
        }

        return () => {
            document.removeEventListener('scroll', StickyCanvas, true);
        };
    }, []);

    return position;
};

const getDoorPreviewOptions = ({
    values,
    productDataStore,
    materials,
    appliedPanel,
    hingeDirection,
    isQFP,
    isEdgeVisible,
}): Object => {
    let width: number = values.cabinet_width;
    let height: number = values.cabinet_height;

    if (typeof height === 'undefined') {
        height = values.cabinet_panel_length;
    }

    if (typeof width === 'undefined') {
        width = values.cabinet_panel_width;
    }

    if (
        typeof width === 'undefined' &&
        values.hasOwnProperty('cabinet_left_width') &&
        values.hasOwnProperty('cabinet_right_width') &&
        values.hasOwnProperty('cabinet_left_depth') &&
        values.hasOwnProperty('cabinet_right_depth')
    ) {
        width = Math.sqrt(
            Math.pow(
                values.cabinet_left_width - values.cabinet_right_depth,
                2
            ) +
                Math.pow(
                    values.cabinet_right_width - values.cabinet_left_depth,
                    2
                )
        );
    }

    if (typeof width === 'undefined') {
        width = values.cabinet_applied_panel_depth;
    }

    const doorType: number = values.hasOwnProperty('door_hang_type')
        ? values.door_hang_type
        : 1;

    let materialImage: string = materials.exterior_color
        ? materials.exterior_color.indexOf('uploads/gocabinet_materials') > -1
            ? `${materials.exterior_color}`
            : `/uploads/gocabinet_materials/${materials.exterior_color}`
        : '/templates/young_beech.jpg';
    let materialEdgeImage: string = materials.exterior_edge_color
        ? materials.exterior_edge_color.indexOf('uploads/gocabinet_materials') >
          -1
            ? `${materials.exterior_edge_color}`
            : `/uploads/gocabinet_materials/${materials.exterior_edge_color}`
        : '/templates/wood2.jpg';

    if (
        materialImage.toLowerCase().indexOf('.jpg') == -1 &&
        materialImage.toLowerCase().indexOf('.png')
    ) {
        materialImage = '/templates/young_beech.jpg';
    }

    if (
        materialEdgeImage.toLowerCase().indexOf('.jpg') == -1 &&
        materialEdgeImage.toLowerCase().indexOf('.png')
    ) {
        materialEdgeImage = '/templates/young_beech.jpg';
    }

    let doorCount = 1;
    let totalDoors = 0;
    let name;
    let hangName;
    let doorVoid = 0;
    let doorVoidSide = 'left';

    if (
        productDataStore.current.hasOwnProperty('cabinet') &&
        productDataStore.current.cabinet.hasOwnProperty('attributes')
    ) {
        if (productDataStore.current.cabinet.attributes.hasOwnProperty('doors'))
            doorCount = productDataStore.current.cabinet.attributes.doors;

        if (
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'original_name'
            )
        )
            name = productDataStore.current.cabinet.attributes.original_name;

        if (
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'door_hang'
            )
        )
            hangName = productDataStore.current.cabinet.attributes.door_hang;
    }

    if (
        values.cabinet_cover_void &&
        values.cabinet_void_width &&
        values.cabinet_void_width > 0
    ) {
        doorVoid = values.cabinet_void_width;

        if (
            productDataStore.current.hasOwnProperty('cabinet') &&
            productDataStore.current.cabinet.hasOwnProperty('attributes') &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'original_name'
            )
        ) {
            if (
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .includes('right return') ||
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .includes('return right')
            ) {
                doorVoidSide = 'right';
            }
        }
    }

    totalDoors = calculateTotalDoors({
        door_count: doorCount,
        door_hang: hangName,
    });

    let options: Object = {
        type: COMPONENT_TYPES.DOOR,
        doorHang: values.door_hang,
        doorHangReverse: false,
        doorType: doorType,
        width: parseInt(width),
        height: parseInt(height),
        doorTop: parseFloat(values.cabinet_door_top),
        doorBottom: parseFloat(values.cabinet_door_bottom),
        doorLeft: parseFloat(values.cabinet_door_left),
        doorRight: parseFloat(values.cabinet_door_right),
        doorGap: values.cabinet_door_gap
            ? parseFloat(values.cabinet_door_gap)
            : 0,
        borderTop: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderRight: 0,
        panelEdgeTop: false,
        panelEdgeBottom: false,
        panelEdgeLeft: false,
        panelEdgeRight: false,
        midRailHorizontalAmount: 0,
        midRailHorizontalHeight: 0,
        midRailVerticalAmount: 0,
        midRailVerticalWidth: 0,
        midRailHorizontalPositions: [],
        midRailVerticalPositions: [],
        glassSubPanels: [[false]],
        edgeMaterial: materialEdgeImage,
        exteriorMaterial: materialImage,
        barsVertical: 0,
        barsHorizontal: 0,
        barsWidth: 0,
        isQFP: isQFP,
        doorCount,
        name,
        hangName,
        doorVoid,
        doorVoidSide,
        partitionHeight: values.hasOwnProperty('cabinet_total_drawer_height')
            ? parseFloat(values.cabinet_total_drawer_height)
            : 0,
        isDrawerDoor:
            doorCount > 0 &&
            values.hasOwnProperty('cabinet_total_drawer_height'),
        isOven: values.hasOwnProperty('oven_opening_height'),
        totalDoors,
    };

    if (isEdgeVisible) {
        options.panelEdgeTop =
            typeof values.panel_edge_top !== 'boolean'
                ? parseInt(values.panel_edge_top) > 0
                : values.panel_edge_top;
        options.panelEdgeLeft =
            typeof values.panel_edge_left !== 'boolean'
                ? parseInt(values.panel_edge_left) > 0
                : values.panel_edge_left;
        options.panelEdgeBottom =
            typeof values.panel_edge_bottom !== 'boolean'
                ? parseInt(values.panel_edge_bottom) > 0
                : values.panel_edge_bottom;
        options.panelEdgeRight =
            typeof values.panel_edge_right !== 'boolean'
                ? parseInt(values.panel_edge_right) > 0
                : values.panel_edge_right;
    }

    if (values.hasOwnProperty('door_hang_type')) {
        if (values.door_hang_type == 3) {
            if (values.cabinet_width_door_2 > 0) {
                options['widths'] = [
                    values.cabinet_width_door_1,
                    values.cabinet_width_door_2,
                ];
            }
        } else {
            options['widths'] = [width, width];
        }
    }

    if (materials?.cabinet_door?.advanced) {
        options = {
            type: COMPONENT_TYPES.DOOR,
            doorHang: values.door_hang,
            doorType: doorType,
            width: parseInt(width),
            height: parseInt(height),
            doorTop: parseFloat(values.cabinet_door_top),
            doorBottom: parseFloat(values.cabinet_door_bottom),
            doorLeft: parseFloat(values.cabinet_door_left),
            doorRight: parseFloat(values.cabinet_door_right),
            doorGap: values.cabinet_door_gap
                ? parseFloat(values.cabinet_door_gap)
                : 0,
            borderTop: parseInt(values.border_width_top),
            borderLeft: parseInt(values.border_width_left),
            borderBottom: parseInt(values.border_width_bottom),
            borderRight: parseInt(values.border_width_right),
            panelEdgeTop:
                typeof values.panel_edge_top !== 'boolean'
                    ? parseInt(values.panel_edge_top) > 0
                    : values.panel_edge_top,
            panelEdgeLeft:
                typeof values.panel_edge_left !== 'boolean'
                    ? parseInt(values.panel_edge_left) > 0
                    : values.panel_edge_left,
            panelEdgeBottom:
                typeof values.panel_edge_bottom !== 'boolean'
                    ? parseInt(values.panel_edge_bottom) > 0
                    : values.panel_edge_bottom,
            panelEdgeRight:
                typeof values.panel_edge_right !== 'boolean'
                    ? parseInt(values.panel_edge_right) > 0
                    : values.panel_edge_right,
            midRailHorizontalAmount: values.hasOwnProperty('hori_amount')
                ? parseInt(values.hori_amount)
                : 0,
            midRailHorizontalHeight: values.hasOwnProperty('hori_height')
                ? parseInt(values.hori_height)
                : 0,
            midRailVerticalAmount: values.hasOwnProperty('vert_amount')
                ? parseInt(values.vert_amount)
                : 0,
            midRailVerticalWidth: values.hasOwnProperty('vert_width')
                ? parseInt(values.vert_width)
                : 0,
            midRailHorizontalPositions: values.hasOwnProperty(
                'hori_mid_rail_positions'
            )
                ? JSON.parse(JSON.stringify(values.hori_mid_rail_positions))
                : [],
            midRailVerticalPositions: values.hasOwnProperty(
                'vert_mid_rail_positions'
            )
                ? JSON.parse(JSON.stringify(values.vert_mid_rail_positions))
                : [],
            glassSubPanels: previewSubpanels(
                values.hasOwnProperty('glassSubPanels')
                    ? validSubpanels(values.glassSubPanels)
                    : Array(3).fill(Array(3).fill(false)),
                values.hasOwnProperty('vert_amount')
                    ? parseInt(values.vert_amount)
                    : 0,
                values.hasOwnProperty('hori_amount')
                    ? parseInt(values.hori_amount)
                    : 0
            ),
            edgeMaterial: materialEdgeImage,
            exteriorMaterial: materialImage,
            barsVertical: values.hasOwnProperty('no_vert_bars')
                ? values.no_vert_bars
                : 0,
            barsHorizontal: values.hasOwnProperty('no_hori_bars')
                ? values.no_hori_bars
                : 0,
            barsWidth: values.hasOwnProperty('bar_width')
                ? parseFloat(values.bar_width)
                : 0,
            widths: options.widths ? options.widths : [],
            isDrawerDoor:
                doorCount > 0 &&
                values.hasOwnProperty('cabinet_total_drawer_height'),
            isOven: values.hasOwnProperty('oven_opening_height'),
            isQFP,
            doorCount,
            name,
            hangName,
            doorVoid,
            doorVoidSide,
            totalDoors,
        };
    }

    if (productDataStore.current.cabinet.attributes.style == 5) {
        // if component
        options.hingeBlack = HingeBlack;
        options.hingeWhite = HingeWhite;
        options.hingeWidth = 15;
        options.drillings = values.drillings ? values.drillings : [];
    }

    if (appliedPanel) {
        options.doorHang = 2;
    }

    if (values.rem_door_shelf_height) {
        options.rem_door_shelf_height = isNaN(values.rem_door_shelf_height)
            ? excel.calculate(values.rem_door_shelf_height, {
                  ...values,
                  ...materials,
              })
            : values.rem_door_shelf_height;
    }

    if (values.cabinet_total_opening_width) {
        if (isNaN(values.cabinet_total_opening_width)) {
            options.cabinet_total_opening_width = excel.calculate(
                values.cabinet_total_opening_width,
                {
                    ...values,
                    ...materials,
                }
            );
        } else {
            options.cabinet_total_opening_width =
                values.cabinet_total_opening_width;
        }
    }

    options.hingeDirection = hingeDirection;
    options.metric = 'mm';

    return options;
};

type DoorCanvasType = {
    appliedPanel: boolean,
    hingeDirection: string,
    isQFP: boolean,
};

export const useDoorCanvas = ({
    appliedPanel,
    hingeDirection,
    isQFP,
}: DoorCanvasType): {
    position: StickyPosition,
    canvasContainerId: string,
} => {
    const isSmallDevice = isDeviceSmall();
    const {values} = useFormikContext();
    const {
        getMaterialOptions,
        productDataStore,
        formStructure,
        doorPreviewOpacity,
        currentTab,
    } = useProductContext();
    const {userProfile} = useAppContext();
    const position: StickyPosition = useStickyCanvas();
    const [focus, setFocus] = useState<string>('');
    const canvasContainerId = useRef<string>('canvas-container-door').current;

    const inputFocusEvent = () => {
        if (document.activeElement) {
            const element = document.activeElement;

            setFocus(element.name);
        }
    };

    useLayoutEffect(() => {
        document.addEventListener('click', inputFocusEvent);

        return () => document.removeEventListener('click', inputFocusEvent);
    }, []);

    const onValueChange = useRef(
        ({values, opacity_, componentPainter, focus_}) => {
            try {
                const materials = getMaterialOptions();
                const options = [];

                const shelvesStructure = formStructure.find(
                    (structure) => structure.name == 'Shelves'
                );
                let previewOptions;

                if (
                    shelvesStructure &&
                    shelvesStructure.hasOwnProperty('preview_options')
                ) {
                    previewOptions = shelvesStructure.preview_options;

                    const shelvesOptions = getShelvesOptions({
                        values,
                        productDataStore,
                        materials,
                        previewOptions,
                        includeVoid: true,
                    });

                    shelvesOptions.horizontal = values.hor_grain_ext;
                    options.push(shelvesOptions);
                }

                const isEdgeVisible = getEdgeVisibility(
                    formStructure,
                    materials,
                    values
                );

                const doorOptions = getDoorPreviewOptions({
                    values,
                    productDataStore,
                    materials,
                    appliedPanel,
                    hingeDirection,
                    isQFP,
                    isEdgeVisible,
                });
                doorOptions.opacity = opacity_;
                doorOptions.focus = focus_;
                doorOptions.horizontal = values.hor_grain_ext;
                doorOptions.doorHangReverse = userProfile.doorHangReversal == 1;

                options.push(doorOptions);

                componentPainter.init(options);
            } catch (e) {
                console.log(e);
            }
        }
    ).current;
    const debouncedOnValueChange = useRef(
        debounce(onValueChange, 100, {
            leading: false,
            trailing: true,
            maxWait: 1000,
        })
    ).current;

    useEffect(() => {
        const componentPainter = new ComponentPainter(
            canvasContainerId,
            isSmallDevice ? 300 : 500,
            true
        );

        debouncedOnValueChange({
            values,
            opacity_: doorPreviewOpacity,
            componentPainter,
            focus_: focus,
        });
    }, [values, currentTab, formStructure, doorPreviewOpacity, focus]);

    return {
        position,
        canvasContainerId,
    };
};

type Position = {
    action: Function,
    displayName: string,
    name: string,
    type: string,
    value: number,
};

export const usePositions = (
    fieldName,
    fieldsetName,
    fieldset: Fieldset,
    isQFP: boolean = false,
    selectHandler: Function = false
): {positions: Array<Position>, isLattice: boolean} => {
    const dispatch = useAppDispatch();
    const {values, setFieldValue} = useFormikContext();
    const [fieldCount, setFieldCount] = useState(0);
    const {data: structure} = useGetQFPProductStructureQuery({
        cabinetType: values.cabinet_type,
    });

    const railAmountRef = useRef(0);

    const isLattice = useMemo(() => {
        let isLattice = false;

        if (fieldset.hasOwnProperty('fields')) {
            isLattice = fieldset.fields[0].options.hasOwnProperty('isLattice');
        }

        return isLattice;
    }, [fieldset]);

    const positions = useMemo(() => {
        const positions = Array.from({length: fieldCount}, (_, index) => {
            const position = cloneDeep(fieldset.fields[0]);
            position.displayName = `Position ${index + 1}`;
            position.name = `${fieldsetName}[${index}]`;
            position.action = (name, value) => {
                const updatedData = JSON.parse(
                    JSON.stringify(values[String(fieldsetName)])
                );
                updatedData[Number(index)] = value;

                if (selectHandler) {
                    selectHandler(`${fieldName}_${index + 1}`, value);
                    selectHandler(fieldsetName, updatedData);
                } else {
                    setFieldValue(`${fieldName}_${index + 1}`, value);
                    setFieldValue(fieldsetName, updatedData);
                }
            };
            position.focusAction = () => {
                if (fieldName == 'rail_hori') {
                    dispatch(activeHorizontalMidRailSet(index));
                } else {
                    dispatch(activeVerticalMidRailSet(index));
                }
            };

            position.blurAction = () => {
                if (fieldName == 'rail_hori') {
                    dispatch(activeHorizontalMidRailSet(undefined));
                } else {
                    dispatch(activeVerticalMidRailSet(undefined));
                }
            };

            position.options['hasDeleteIcon'] = true;
            position.options['deleteAction'] = () => {
                const field =
                    fieldName === 'rail_hori' ? 'hori_amount' : 'vert_amount';
                // decrease the current field count
                setFieldValue(field, fieldCount - 1);

                const updatedData = JSON.parse(
                    JSON.stringify(values[String(fieldsetName)])
                );
                updatedData.splice(index, 1);
                setFieldValue(fieldsetName, updatedData);
            };

            return position;
        });

        return positions;
    }, [fieldCount, values, fieldName]);

    const setValue = useCallback(
        (changedField, positionCount) => {
            if (structure && structure.isLattice) return;
            const fieldName =
                changedField == 'horizontal'
                    ? 'hori_mid_rail_positions'
                    : 'vert_mid_rail_positions';
            const isVerticalRail = changedField != 'horizontal';

            const currentPositionArray = values[String(fieldName)];
            const currentPositions = cloneDeep(
                currentPositionArray ? currentPositionArray : []
            );
            currentPositions.length = positionCount;

            const cabinetWidth: number = isQFP
                ? values.width
                : values.cabinet_width;
            const cabinetHeight: number = isQFP
                ? values.height
                : values.cabinet_height;

            let doorLength: number;
            if (isVerticalRail) {
                let width: number = cabinetWidth;

                if (
                    values.hasOwnProperty('cabinet_applied_panel_depth') &&
                    !isQFP
                ) {
                    width = values.cabinet_applied_panel_depth;
                }

                if (
                    typeof width === 'undefined' &&
                    values.hasOwnProperty('cabinet_left_width') &&
                    values.hasOwnProperty('cabinet_right_width') &&
                    values.hasOwnProperty('cabinet_left_depth') &&
                    values.hasOwnProperty('cabinet_right_depth')
                ) {
                    width = Math.sqrt(
                        Math.pow(
                            values.cabinet_left_width -
                                values.cabinet_right_depth,
                            2
                        ) +
                            Math.pow(
                                values.cabinet_right_width -
                                    values.cabinet_left_depth,
                                2
                            )
                    );
                }

                doorLength = width;
            } else {
                doorLength = cabinetHeight;
            }

            if (
                currentPositions.length == 1 &&
                (currentPositions[0] == 0 ||
                    typeof currentPositions[0] == 'undefined')
            ) {
                currentPositions[0] = doorLength / 2;
            }

            if (selectHandler) {
                selectHandler(
                    fieldName,
                    Array.from(currentPositions, (value) => value || '')
                );
            } else {
                setFieldValue(
                    fieldName,
                    Array.from(currentPositions, (value) => value || '')
                );
            }
        },
        [values, fieldName, structure]
    );

    useEffect(() => {
        if (
            fieldName == 'rail_hori' &&
            railAmountRef.current != values.hori_amount
        ) {
            railAmountRef.current = values.hori_amount;
            const changedField = 'horizontal';
            const horiAmount = isNaN(values.hori_amount)
                ? 0
                : values.hori_amount;

            setFieldCount(horiAmount);
            setValue(changedField, horiAmount);
        }

        if (
            fieldName == 'rail_vert' &&
            railAmountRef.current != values.vert_amount
        ) {
            railAmountRef.current = values.vert_amount;
            const changedField = 'vertical';
            const vertAmount = isNaN(values.vert_amount)
                ? 0
                : values.vert_amount;

            setFieldCount(vertAmount);
            setValue(changedField, vertAmount);
        }
    }, [values.vert_amount, values.hori_amount]);

    useEffect(() => {
        if (structure && structure.isLattice) {
            const width = values.width;
            const totalWidth = width;
            const paneWidth = totalWidth / (values.vert_amount + 1);

            const panes = Array.from(
                {length: values.vert_amount},
                () => paneWidth
            );
            selectHandler('vert_mid_rail_positions', panes);

            const height = parseInt(values.height);
            const borderWidthBottom = parseInt(
                values.border_width_bottom ? values.border_width_bottom : 0
            );
            const borderWidthTop = parseInt(
                values.border_width_top ? values.border_width_top : 0
            );
            const horizontalHeight = parseInt(
                values.hori_height ? values.hori_height : 0
            );
            const amount = parseInt(values.hori_amount);
            const totalHeight =
                height -
                borderWidthBottom -
                borderWidthTop -
                horizontalHeight * amount;

            const paneHeight = totalHeight / (amount + 1);
            const verticalHeights = Array.from({length: amount}, (_, i) => {
                if (i == 0) {
                    return (
                        borderWidthBottom + paneHeight + horizontalHeight / 2
                    );
                } else {
                    return (
                        borderWidthBottom +
                        paneHeight * (i + 1) +
                        horizontalHeight * (0.5 + i * 1)
                    );
                }
            });
            selectHandler('hori_mid_rail_positions', verticalHeights);
        }
    }, [values.width, values.height, values.hori_amount, structure]);

    return {positions, isLattice};
};

const useSizePercentage = (value, fieldsetName) => {
    const {setFieldValue} = useFormikContext();
    const {shelvesHeightReference, shelvesHeightUpdated, shelvesPositionType} =
        useProductContext();
    const [heightReference, setHeightReference] = useState(0);

    const [decimalValue, setDecimalValue] = useState(0);
    const [displayValue, setDisplayValue] = useState(0);
    const [showPercentage, setShowPercentage] = useState(value < 1);

    useEffect(() => {
        setHeightReference(shelvesHeightReference.current[fieldsetName]);
        setFieldValue('shelvesHeightReference', shelvesHeightReference.current);
    }, [shelvesHeightUpdated]);

    useEffect(() => {
        if (value > 1 && heightReference) {
            setDecimalValue(value / heightReference);
        } else {
            setDecimalValue(value ? parseFloat(value) : 0);
        }
    }, [value, heightReference]);

    useEffect(() => {
        const positionType = shelvesPositionType[fieldsetName];
        if (positionType) {
            setShowPercentage(positionType === POSITION_TYPES.PERCENTAGE);
        }
    }, [shelvesPositionType[fieldsetName]]);

    useEffect(() => {
        if (showPercentage) {
            setDisplayValue(`${+(decimalValue * 100).toFixed(4)}%`);
        } else {
            if (typeof heightReference !== 'undefined') {
                setDisplayValue(+(heightReference * decimalValue).toFixed(4));
            }
        }
    }, [showPercentage, decimalValue, heightReference]);

    return {
        displayValue,
        setDisplayValue,
        setShowPercentage,
        showPercentage,
        decimalValue,
        setDecimalValue,
        heightReference,
        shelvesPositionType,
    };
};

export default useSizePercentage;
